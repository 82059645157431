import { Injectable, Injector, Signal, computed } from '@angular/core';
import { ContextMenuItem } from '@em4cloud/my-cdk';
import { Area, BpmAreasService, ObjectExplorerItemType } from 'app/api';
import { ActionDescriptor } from 'app/core/dialogBuilder/dialog-builder.models';
import { Observable } from 'rxjs';
import { OrgSysAreaService } from '../org-sys-area.service';

@Injectable({ providedIn: 'root' })
export class AreaService extends OrgSysAreaService<Area, BpmAreasService> {
    allowedSelectionList: string[] = [];
    contextMenuItems: Signal<ContextMenuItem<any>[]> = computed(() => [
        {
            id: 'createSiblingItem',
            title: this._translocoContent()['workflow.createSiblingItem'],
            isVisible: this.visibilityOfDevelopmentFunctionalities(),
        },
        {
            id: 'createChildItem',
            title: this._translocoContent()['workflow.createChildItem'],
            isVisible: this.visibilityOfDevelopmentFunctionalities(),
        },
        {
            id: 'delete',
            title: this._translocoContent()['delete'],
            isVisible: this.visibilityOfDevelopmentFunctionalities(),
        },
    ]);

    protected override objectExplorerItemType: ObjectExplorerItemType = ObjectExplorerItemType.Area;

    constructor(inject: Injector) {
        super(inject);
        this._apiService = inject.get(BpmAreasService);

        this.defaultConstructor();
    }

    override findRootParentNode(id: string): Area {
        setTimeout(() => {
            this.treeService.orgData.set(this.state());
        });

        return this.treeService.findRootParentNode(id);
    }

    private findParent(area: Area, targetId: string): Area | null {
        // If the current area matches the target ID, return this area (base case)
        if (area.id === targetId) {
            return area;
        }

        // Recursively check the children to find the target ID
        if (area.children) {
            for (let child of area.children) {
                const foundArea = this.findParent(child, targetId);
                if (foundArea) {
                    return foundArea;
                }
            }
        }

        return null; // Return null if the target ID is not found
    }

    // Function to find the root parent of a given Area ID
    public findRootParent(areas: Area[], targetId: string): Area | null {
        // Helper function to recursively find the parent of the area with the given ID

        if (!areas || !areas.length) {
            return null;
        }

        // Iterate over the top-level areas to find the root parent
        for (let area of areas) {
            const result = this.findParent(area, targetId);
            if (result) {
                // If the result is found, return the root (top-level area)
                return area;
            }
        }

        return null; // Return null if no area with the given ID is found
    }

    protected setUpCreateNewItemActions(): ActionDescriptor[] {
        return [
            {
                code: 'area',
                style: 'icon-button',
                tooltip: this._translocoContent()['createArea'],
                icon: 'heroicons_outline:building-office-2',
            },
        ];
    }

    protected canBeDeleted(isRoot: boolean): boolean {
        return true;
    }
    getNodeIcons(nodeType: string): string {
        switch (nodeType) {
            case 'root':
            default:
                return 'heroicons_outline:map';
        }
    }
    protected handleGetResult(orgId: string, data: [Area[]]): void {
        this.filteredItems.set(data[0]);
        this._items.set(data[0]);
    }
    protected createItemWithAPI(item: Area, organisation?: string): Observable<Area> {
        if (!item.organisation) {
            item.organisation = organisation;
        }
        return this._apiService.createArea(item);
    }
    protected updateItemWithAPI(items: Area[]): Observable<Area[]> {
        return this._apiService.updateArea(items);
    }
    protected deleteItemWithAPI(id: string, organisation?: string): Observable<string> {
        return this._apiService.deleteArea(id, organisation);
    }
    protected getItemsWithAPI(organisation?: string): Observable<Area[]> {
        if (!organisation) {
            organisation = localStorage.getItem('organisation');
        }
        return this._apiService.getAreas(organisation);
    }
    protected getActiveItemWithAPI(organisation?: string): Observable<Area[]> {
        if (!organisation) {
            organisation = localStorage.getItem('organisation');
        }
        return this._apiService.getAreas(organisation, 'ACTIVE');
    }
    protected activateWithAPI(id: string, versionId: string): Observable<Area> {
        return this._apiService.activateArea(id, versionId);
    }
}
